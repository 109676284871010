import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrPromoteModuleIndexView',
  computed: {
    ...mapState('promote', ['referralCodes']),
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrPromoteModuleIndexView extends GtrSuper {
  siteLink?: string;

  data () {
    return {
      payment_processor_currency: null,
      loading: false,
      event_all_content: null,
      reg_url: null,
      referral_codes: [],
      referral_code_to_delete: null
    }
  }

  get promoteModule () {
    return this.$route?.meta?.module || 'register'
  }

  get newReferralTrackingLinkRoute () {
    if (this.promoteModule === 'leads') {
      return 'level-two.modules.leads.promote.create'
    }
    return 'level-two.modules.registration.promote.create'
  }

  get promoteEditRoute () {
    if (this.promoteModule === 'leads') {
      return 'level-two.modules.leads.promote.edit'
    }
    return 'level-two.modules.registration.promote.edit'
  }

  async mounted () {
    await this.fetchReferralCodes()
    this.$data.referral_codes = this.$store.state.promote.referralCodes
  }

  private async fetchReferralCodes () {
    try {
      this.$data.loading = true
      let type = 'REGISTRATION'
      if (this.promoteModule === 'leads') {
        type = 'LRORDERS'
      }
      await this.$store.dispatch('promote/getReferralCodes', { event_uuid: this.$route.params.event_uuid, type })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleDeleteReferralCode (payload: any) {
    this.$data.referral_code_to_delete = payload
  }

  async onDeleteReferralCodeAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.loading = true
        const data: any = {
          event_uuid: this.$route.params.event_uuid,
          referral_code_uuid: this.$data.referral_code_to_delete
        }
        await this.$store.dispatch('promote/deleteReferralCode', data)
        Container.get(Notification).success('Referral code successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
    this.$data.referral_code_to_delete = null
  }

  onCopy () {
    Container.get(Notification).success('Successfully copied link.')
  }

  onError () {
    Container.get(Notification).error('There was an error copying the link. Please refresh and try again.')
  }

  onCopyReferralSuccess () {
    Container.get(Notification).success('Successfully copied referral url.')
  }

  onCopyReferralError () {
    Container.get(Notification).error('There was an error copying the referral link. Please refresh and try again.')
  }

  async exportReferralCode (referral_code_uuid: string): Promise<void> {
    try {
      const { event_uuid } = this.$route.params
      const response = await this.$store.dispatch('promote/exportReferralCode', {
        event_uuid,
        referral_code_uuid
      })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(Notification).error('There was an error exporting your referral code.')
    }
  }

  async archiveReferralCode (referral_code_uuid: string, active = 0): Promise<void> {
    const { event_uuid } = this.$route.params
    await this.$store.dispatch('promote/archiveReferralCode', {
      event_uuid,
      referral_code_uuid,
      active
    })
  }

  getStatsHeading (key: string): string | undefined {
    switch (key) {
      case 'click_count':
        return 'Visitors'
      case 'participant_usage':
        return 'Engagement'
      case 'participant_usage_complete':
        return 'Completed Registrations'
      case 'revenue_total':
        return 'Revenue'
      default:
        return ''
    }
  }

  getStatsTooltip (key: string): string | undefined {
    switch (key) {
      case 'click_count':
        return 'People who have visited this event site using this referral code.'
      case 'participant_usage':
        return 'People who have started a registration using this referral code'
      case 'participant_usage_complete':
        return 'Registrations that have been completed that used this referral code'
      case 'revenue_total':
        return 'Revenue generated by this referral code'
      default:
        return ''
    }
  }

  getStatsRoute (
    key: string | number,
    search_value: Record<string, any>
  ): Record<string, any> | boolean {
    const route: {
      name: string;
      params: Record<string, any>;
    } = {
      name: '',
      params: {
        event_uuid: this.$route.params.event_uuid,
        search_field: null,
        search_value: null
      }
    }

    /**
     * TODO: rework route to use query instead of params.
     * https://router.vuejs.org/api/index.html#query
     *
     * also update the attendees view component to handle
     */
    switch (key) {
      case 'click_count':
        return false
      case 'participant_usage':
        route.name = 'level-two.event.attendees.search'
        route.params.search_field = 'referral_code'
        route.params.search_value = search_value.code
        return route
      default:
        route.name = 'level-two.event.attendees.referral.search.status'
        route.params.referral_module = this.promoteModule === 'leads' ? 'leads' : 'register'
        route.params.referral_value = search_value.code
        route.params.participant_status = 'Complete'
        return route
    }
  }

  get siteLinkButtonText () {
    if (this.promoteModule === 'leads') {
      return 'Copy Leads Site Link'
    }
    return 'Copy Registration Site Link'
  }

  get regUrlWithReferralCodes () {
    const refCodes = this.$data.referral_codes
    if (refCodes && this.$data.reg_url) {
      refCodes.forEach(code => {
        Object.assign(code, {
          referral_code_url: this.$data.reg_url + '?referralCode=' + code.code
        })
      })
    }
    return refCodes
  }

  @Watch('referralCodes')
  onReferralCodesChange (payload: any) {
    if (payload && Array.isArray(payload)) {
      this.$data.referral_codes = payload
    }
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    this.$data.event_all_content = value
    if (this.$data.event_all_content) {
      this.$data.reg_url = `${process.env.VUE_APP_REG_URL}/${this.$data.event_all_content.event.event_identifier}`
      if (this.promoteModule === 'leads') {
        this.$data.reg_url = this.$data.reg_url + '/leads'
      }
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }
}
